import { StickyHeaderTable } from 'components/ui/Table/StickyHeaderTable';

import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { useUI } from 'contexts/UiContext';
import { FC, useCallback, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useArchiveFilesContext } from './ArchiveFilesContext';

import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';

import { useHistory } from 'react-router-dom';

export interface IArchiveFilesViewProps {
  title?: string;
  onReady?: () => void;
  printView?: boolean;
  tableHead: IHeadCell[];
  viewPath: string;
}

export const ArchiveFilesFilteredView: FC<IArchiveFilesViewProps> = ({
  onReady,
  printView,
  tableHead,
  viewPath,
}) => {
  const { addSnackbar } = useUI();
  const history = useHistory();

  const {
    totalItems,
    documents,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterOptions,
    filterValues,
    error,
  } = useArchiveFilesContext();

  const onArchiveFileSelect = useCallback(
    (archiveFileId: string) => {
      history.push(viewPath.replace(':archiveFileId', archiveFileId));
      return true;
    },
    [history, viewPath]
  );

  useEffect(() => {
    if (!!onReady && !loading && documents) {
      onReady();
    }
  }, [loading, documents, onReady]);

  useEffect(() => {
    if (!loading && !!error) {
      apolloErrorHandler(addSnackbar!)(error);
    }
  }, [loading, error, addSnackbar]);

  const onSelect = useCallback(
    (id: string) => {
      onArchiveFileSelect && onArchiveFileSelect(id);
      return true;
    },
    [onArchiveFileSelect]
  );

  return (
    <LoadingOverlay spinner active={loading && !printView} text="Loading your content...">
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Box
            style={{
              borderRadius: '1px',
              boxShadow: '0px 2px 5px #00000030',
              border: '1px solid #00000030',
            }}
          >
            <StickyHeaderTable
              hideFilters={true}
              totalItems={totalItems}
              dataCells={documents}
              headCells={tableHead}
              loadPage={loadPage}
              handleSelect={onSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
              printView={printView}
              initOrder={pageLoadParams.order || SortOrder.ASC}
              initOrderBy={pageLoadParams.orderBy}
              initPage={pageLoadParams.page}
            />
          </Box>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};
